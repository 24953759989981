import * as React from "react"
import { Link } from "gatsby"

import "./elevatedButton.scss"

type ElevatedButtonProps = {
  label: string
  iconClassName?: string
  href?: string
  isExternalLink?: boolean
  disabled?: boolean
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
}

const ElevatedButton = ({
  label,
  iconClassName,
  href,
  isExternalLink = false,
  disabled = false,
  onBtnClick,
}: ElevatedButtonProps) => {
  const classNames = "elevated-button bg-tertiary shadow pl-2 pr-2"

  const content = iconClassName ? (
    <React.Fragment>
      <i className={iconClassName}></i>
      <p className="button ml-1">{label}</p>
    </React.Fragment>
  ) : (
    <p className="button">{label}</p>
  )

  if (href && href.length > 0) {
    // Has href, must be a links
    const attributes = isExternalLink
      ? { target: "_blank", rel: "noreferrer" }
      : {}

    if (isExternalLink) {
      return (
        <a href={href} className={classNames} {...attributes}>
          {content}
        </a>
      )
    } else if (href.startsWith("tel:") || href.startsWith("mailto:")) {
      return (
        <a href={href} className={classNames}>
          {content}
        </a>
      )
    } else {
      return (
        <Link to={href} className={classNames}>
          {content}
        </Link>
      )
    }
  } else {
    // Has no href, can't be a links
    return (
      <button disabled={disabled} className={classNames} onClick={onBtnClick}>
        {content}
      </button>
    )
  }
}

export default ElevatedButton
