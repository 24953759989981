import * as React from "react"
import { useLocation } from "@reach/router"

import { TagManagerContext } from "../../core/contexts/tagManager"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Divider from "../../components/divider/divider"
import Card from "../../components/card/card"
import ElevatedButton from "../../components/buttons/elevatedButton"

const description =
  "As a digitally-transformed business, digital content becomes an absolute need. Furthermore, with the proliferation of competition online, strong, opinionated, and effective forms of digital content become key to realizing the exponential growth and experience for your business and users."

const DigitalContentPage = () => {
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "page",
    })
  }

  return (
    <Layout>
      <Seo title="Services | Digital Content" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Digital Content</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
        <div className="mt-5 mb-5 pt-5 pb-5 mw-800">
          <h6>Content Strategy</h6>
          <p>
            Based on findings and insights acquired from user testing and data,
            we craft intelligent roadmaps for particular content and how to
            position it best for users. iPhupha Digital can help you define and
            create a clear customer-focused vision that you can communicate
            within your organization. Getting an organization to act with one
            voice is as important for user experience as it is for brand.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Content Design</h6>
          <p>
            We understand the importance of creating something that is
            functional and usable — for everyone. The format your content takes
            is vital. You need to think about various abilities and reading
            levels, as well as considering the growing number of users who
            don&apos;t have English as their first language among other factors.
            iPhupha Digital creates assets that will align with the
            business&apos; goals for each digital property.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Content Management</h6>
          <p>
            We develop and maintain headless content management systems on any
            platform. We can augment, refine and grow pre-existing content
            management systems. We also make recommendations for best practices
            and solutions for state of the art content management systems and
            their successful implementation and maintenance.
          </p>
        </div>
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="container pt-5 pb-5">
        <Card isPrimary={true} canHover={false}>
          <div className="pt-2 pb-2">
            <div className="row justify-space-between align-center">
              <h6 className="mb-3">How can we help you?</h6>
              <span className="text-black">
                <div
                  onClick={() =>
                    onLinkClick({
                      label: "Get in touch with us",
                      href: "/contact-us",
                    })
                  }
                >
                  <ElevatedButton
                    label="Get in touch with us"
                    href="/contact-us"
                  />
                </div>
              </span>
            </div>
          </div>
        </Card>
      </section>
      <div className="container">
        <Divider />
      </div>
    </Layout>
  )
}

export default DigitalContentPage
